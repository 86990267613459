<template>
    <div class="loginbox">
        <div class="lang">
            <van-dropdown-menu :overlay="false" active-color="#004ea3">
                <van-dropdown-item v-model="lang" :options="option" @change="Language" />
            </van-dropdown-menu>
        </div>
        <div class="kefu" @click="toserver">
            {{ $t('login.serverurl') }}
        </div>
        <div class="loginBody-image">
            <van-image width="4rem" height="4rem" :src="require('../../assets/img/login.png')" />
        </div>
        <div class="loginBody">
            <van-row>
                <van-cell-group>
                    <van-field v-model="loginform.username" clearable :placeholder="$t('login.username')">
                        <template #left-icon>
                            <van-icon class="iconfont" class-prefix="icon" name="yonghuming" />
                        </template>
                    </van-field>
                    <van-field v-model="loginform.password" :type="type" right-icon="closed-eye"
                        :placeholder="$t('login.password')" @click-right-icon="openeye">
                        <template #left-icon>
                            <van-icon class="iconfont" class-prefix="icon" name="mima" />
                        </template>
                    </van-field>
                </van-cell-group>
            </van-row>
            <van-row type="flex" justify="space-between" class="foundPwd">
                <van-col span="12" @click="forget">{{ $t('login.forget') }}</van-col>
                <van-col span="12" @click="register">
                    {{ $t('login.register') }}
                </van-col>
            </van-row>
            <van-button :loading="loading" :loading-text="$t('login.loging')" @click="login">
                {{ $t('login.login') }}
            </van-button>
            <van-button :loading="loading" loading-text="MetaMask" @click="initWeb3"
                style="margin: 15px;background-color: #F5841F;">
                MetaMask
            </van-button>
            <van-row class="agreement">
                <van-checkbox v-model="checked" shape="square">{{ $t('login.read') }}
                </van-checkbox>
                <span style="color:#004ea3" @click="agreement">
                    {{ $t('login.agreement') }}
                </span>
            </van-row>
        </div>
    </div>
</template>
<script>
// function setCookie(cname, cvalue, exdays) {
//   var d = new Date()
//   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
//   var expires = 'expires=' + d.toUTCString()
//   document.cookie = cname + '=' + cvalue + '; ' + expires
// }
// setCookie('googtrans', '/auto/en', 100)
import Web3 from 'web3';
export default {
    data() {
        return {
            lang: localStorage.getItem('lang') || 'en',
            option: [
                { text: '中文', value: 'zh' },
                { text: 'English', value: 'en' },
                { text: 'русский язык', value: 'ru' },
                { text: 'Français', value: 'fr' },
                { text: 'Español', value: 'es' },
                { text: 'Italiano', value: 'it' }
            ],
            loginform: {
                username: '',
                password: ''
            },
            type: 'password',
            loading: false,
            checked: true,
            serverurl: ''
        };
    },
    created() {
        function getPar(par) {
            // 获取当前URL
            let localUrl = document.location.href;
            // 获取要取得的get参数位置
            let get = localUrl.indexOf(par + '=');
            if (get === -1) {
                return false;
            }
            // 截取字符串
            let getPar = localUrl.slice(par.length + get + 1);
            // 判断截取后的字符串是否还有其他get参数
            var nextPar = getPar.indexOf('&');
            if (nextPar !== -1) {
                getPar = getPar.slice(0, nextPar);
            }
            return getPar.slice(0, 2);
        }
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'en');
        }
        this.getserver();
        console.log(this.lang);
        this.getlang('token不能为空', this.lang);
        this.initWeb3()
    },
    methods: {
        openeye(event) {
            if (this.type === 'password') {
                event.target.className = 'van-icon van-icon-eye';
                this.type = 'text';
            } else {
                event.target.className = 'van-icon van-icon-closed-eye';
                this.type = 'password';
            }
        },
        // 获取客服链接
        async getserver() {
            const { data } = await this.$http.get('/home/index/serviceurl');
            if (data) {
                if (data.code === 200) {
                    this.serverurl = data.data;
                }
            }
        },
        // 登陆
        async login() {
            if (!this.checked) {
                this.$toast.fail(this.$t('login.readagreement'));
                return false;
            }
            if (this.loginform.username === '' || this.loginform.password === '') {
                this.$toast.fail(this.$t('login.complete'));
                return false;
            }
            this.loading = true;
            const { data } = await this.$http.post(
                '/home/index/login',
                this.loginform
            );
            if (data) {
                if (data.code === 200) {
                    console.log(data.data.token);
                    this.$toast.success(this.getlang(data.msg, this.lang));
                    window.localStorage.setItem('token', data.data.token);
                    this.$router.push('/');
                } else {
                    this.$toast.fail(this.getlang(data.msg, this.lang));
                }
            }
            this.loading = false;
        },
        // 钱包登录
        async initWeb3() {
            if (!window.ethereum) return this.$toast.fail("请安装 MetaMask!");
            const web3 = new Web3(window.ethereum);
            try {
                // 请求用户授权账户信息
                await window.ethereum.request({
                    method: 'eth_requestAccounts'
                });
                // 获取账户
                const accountsArray = await web3.eth.getAccounts();
                const address = accountsArray[0];
                localStorage.setItem('web3_address', address); // 默认获取第一个钱包地址

                // 发送登录请求
                this.loginform.username = address;
                this.loginform.password = 'hsdkjjakljdkkad';

                this.loading = true;
                const { data } = await this.$http.post('/home/index/login', this.loginform);
                if (data) {
                    if (data.code === 200) {
                        this.$toast.success(this.getlang(data.msg, this.lang));
                        window.localStorage.setItem('token', data.data.token);
                        this.$router.push('/');
                    } else {
                        // this.$toast.fail(this.getlang(data.msg, this.lang));
                        // 为钱包注册登录账号
                        const res = await this.$http.post('/home/index/register', {
                            password: this.loginform.password,
                            repassword: this.loginform.password,
                            type: 2,
                            username: this.loginform.username
                        });
                        // 注册成功
                        if (res.data.code == 200) {
                            // 登录账号
                            const res2 = await this.$http.post('/home/index/login', this.loginform);
                            if (res2.data.code == 200) {
                                this.$toast.success(this.getlang(res2.data.msg, this.lang));
                                window.localStorage.setItem('token', res2.data.data.token);
                                this.$router.push('/');
                            }

                        }
                        // 注册失败
                        else {
                            this.$toast.fail(res.data.msg);
                        }
                    }
                }
                this.loading = false;


            } catch (error) {
                // 用户拒绝登入
                if (error.code == 4001) {
                    this.$toast.fail(error.message);
                }
                // 其他错误
                else {
                    this.$toast.fail(error.message);
                }
            }
        },
        // 用户协议
        agreement() {
            this.$router.push({ path: '/agreement' });
        },
        // 忘记密码
        forget() {
            this.$router.push({ path: '/forget' });
        },
        // 快速注册
        register() {
            this.$router.push({ path: '/register' });
        },
        Language() {
            let lang = this.lang;
            this.$i18n.locale = lang || 'en';
            localStorage.setItem('lang', lang);

            // setCookie('googtrans', '/auto/en', 100)
            // setCookie('googtrans', '', -1111111111111)
            // if (this.$i18n.locale === 'zh') {
            //   this.$i18n.locale = 'en'
            //   localStorage.setItem('lang', 'en')
            //   // setCookie('googtrans', '/auto/en', 100)
            // } else {
            //   this.$i18n.locale = 'zh'
            //   localStorage.setItem('lang', 'zh')
            //   // setCookie('googtrans', '/auto/zh', 100)
            //   // this.$http.get('/home/index/language/zh').then(res => {
            //   //   console.log(res)
            //   // })
            // }
            // window.location.reload()
        },
        // 客服页面
        toserver() {
            window.location.href = this.serverurl;
        }
    }
};
</script>

<style lang="less" scoped>
/deep/ .van-field__control {
    color: #097551;
}

/deep/ .van-cell {
    background-color: #ffff;
}

.loginbox {
    height: 100%;
    width: 100%;
    background: url('../../assets/img/login-bg.png'); //登录也背景图
    background-size: cover;
    position: relative;
    padding: 2.75rem 2rem 0 2rem;

    .lang {
        position: absolute;
        right: 1.25rem;
        top: 0.8rem;
        color: #ffffff;
        font-size: 0.875rem;

        .van-dropdown-menu /deep/.van-dropdown-menu__bar {
            box-shadow: none;
            height: 1rem;
            background: transparent !important;
        }

        .van-dropdown-menu /deep/ .van-dropdown-menu__title {
            color: #0055ff !important; //语言字体
        }

        .van-dropdown-menu /deep/ .van-popup {
            width: 30%;
            left: 68%;
            border-radius: 0.4rem;
            box-shadow: 0 0.13333rem 0.4rem #004ea3;
        }

        .van-dropdown-item__option,
        .van-dropdown-item__option--active {
            margin: 0;
            text-align: center;
        }

        .van-cell__title,
        .van-dropdown-item__option--active .van-dropdown-item__icon {
            color: #004ea3 !important;
        }
    }

    .kefu {
        position: absolute;
        right: 1rem;
        top: 2.4rem;
        font-size: 0.875rem;
        color: #0055ff; //客服字体
    }

    .loginBody-image {
        margin-top: 25%;
        height: 20%;
        z-index: 1;
    }

    .loginBody {
        margin-top: 15%;
        margin-left: 5%;
        width: 90%;
        padding-bottom: 3%;
    }

    .van-image__error,
    .van-image__img,
    .van-image__loading {
        display: block;
        width: 100%;
        height: 100%;
    }

    .van-field {
        padding: 30px 0 15px 0;
        font-size: 1rem;

        /deep/.van-cell-group van-hairline--top-bottom,
        {
        color: #004ea3;
    }

    /deep/.van-field__right-icon,
    /deep/.van-field__left-icon {
        color: #004ea3;
        font-size: 1.2rem;
    }

    .van-icon-clear {
        color: #b3b3b3;
        font-size: 1rem;
    }

    .van-field__control {
        padding-left: 5px;
    }
}

.foundPwd {
    font-size: 0.875rem;
    line-height: 3rem;

    .van-col:first-child {
        text-align: left;
        color: #004ea3;
    }

    .van-col:last-child {
        text-align: right;
        color: #004ea3;
    }
}

.van-button {
    margin-top: 28%;
    width: 60%;
    height: 2.66667rem;
    font-size: 0.93333rem;
    color: #ffffff;
    background-color: #0055ff;
    border-radius: 1.6rem;
    border: none;
}

.agreement {
    margin-top: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;

    /deep/.van-icon {
        height: 1rem;
        width: 1rem;
        font-size: 0.66667rem;
        color: #fff !important;
        border-radius: 4px;
    }

    /deep/.van-checkbox__label {
        margin-left: 3px;
        margin-right: 3px;
    }

    .van-checkbox__icon--checked .van-icon {
        color: #fff !important;
        background-color: #004ea3;
        border-color: #004ea3;
        border-radius: 4px;
        font-size: 0.66667rem;
    }
}

.iconfont {
    color: #004ea3;
    font-size: 18px;
}
}
</style>
